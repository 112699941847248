<template>
  <div class="position-relative">
    <loading-overlay :active="loadingPdf" :is-full-page="true" loader="bars" />
    <loading-overlay :active="loading" :is-full-page="false" loader="bars" />
    <HoldModal
      :modal.sync="holdModal"
      :vesselHoldId="vesselHoldId"
      :holdName="holdName"
      :total="holdTotal"
      @closed="handleAction"
    />
    <CRow>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mt-2">
        <CButton
          shape="square"
          color="excel"
          size="sm"
          class="ml-2"
          @click.prevent="printPdf"
        >
          <div class="d-flex align-items-center">
            <CIcon name="cil-cloud-download"/>
            <span class="ml-1">{{$t('label.download')}}</span>
          </div>
        </CButton>
      </CCol>
    </CRow>
    <CRow alignHorizontal="center" class="mt-2">
      <CCol sm="8" class="d-flex align-items-center justify-content-center wrap-legend">
        <div
          v-for="(item, index) in formatedLegend"
          :key="index"
          class="legend-item"
          v-c-tooltip="{
            content: item['Status'+$i18n.locale.charAt(0).toUpperCase() + $i18n.locale.slice(1)],
            placement: 'top-center',
          }"
        >
          <div class="legend-color" :style="{ backgroundColor: item.Color }"></div>
          <div class="legend-text">
            {{ 
              item.StowagePlanningBlHoldId 
                ? item.AliasBl
                : item['Status'+$i18n.locale.charAt(0).toUpperCase() + $i18n.locale.slice(1)]
            }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" class="d-flex align-items-center justify-content-center mt-2">
        <transversal-view :billOfLading="items" manifest @open-hold="currentHold" :VesselType="TpVesselMasterId"/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import TransversalView from '@/pages/buque/bays/transversal-view';
import CImgExtended from '@/components/CImgExtended';
import { GenerateSvgPlaceholder } from '@/_helpers/funciones';
import { mapState } from "vuex";

import HoldModal from "./hold-modal";

//Created:
function created() { }

//Data:
function data() {
  return {
    loading: true,
    items: [],
    ValidateReport: false,
    updateView: false,
    holdName: null,
    holdModal: false,
    vesselHoldId: '',
    loadingPdf: false,
  }
}

//Methods:
function getDataFromService() {
  this.loading = true;
  this.ValidateReport = false;
  this.$http.ejecutar('GET', 'StowagePlanningVesselHoldBl-list', { StowagePlanningId: this.planificacionId })
  .then(response => {
    this.items = response.data.data || [];
    let nulo = String(response.data.data[0].Json);
    if(this.items.length > 0 && nulo != "null"){
      this.validateReport(response.data.data);
    }
  }).catch( err => {
    this.items = [];
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loading = false;
  });
}
function validateReport(positions) {
  positions.map((item) => {
    if (item.FgHold) {
      if (item.PositionJson[0].OccupiedTon > 0) {
        this.ValidateReport = true;
      }
    }
  });
}
function currentHold(hold) {
  this.vesselHoldId = hold.PositionJson[0].VesselHoldId;
  this.holdName = hold.PositionJson[0].VesselHoldName;
  this.holdModal = true;
}
function handleAction(){
  this.getDataFromService();
}
async function printPdf() {
  if (this.ValidateReport) {
    let pageInfo = {
      StowagePlanningId: this.planificacionId,
      TpVesselId: this.TpVesselMasterId,
      FgGeneralCargo: this.FgGeneralCargo,
    };
    localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

    let routeData = this.$router.resolve({name: 'pdfStowagePlan'});
    window.open(routeData.href, '_blank');
  } else {
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error" 
    });
  }
    
}

//Computerd: 
function placeholder() {
  return GenerateSvgPlaceholder({
    width: 800,
    height: 250,
    text: `${this.$t('label.transversalView')} ${this.$t('label.notAvailable')}`
  });
}

function legend() {
  if(this.items.length == 0) return [];
  let legendItems = [];
  for (let index = 0; index < this.items.length; index++) {
    if(this.items[index].FgHold)
      legendItems = legendItems.concat(this.items[index].PositionJson[0].BlJson);
  }
  return legendItems;
}
function formatedLegend() {
  let formatedLegend = [];
  let found = this.legend.find((item) => item.StatusEs == 'DISPONIBLE');
  if(found){
    formatedLegend.push(found);
    formatedLegend = formatedLegend.concat(this.legend.filter((item) => item.StowagePlanningBlHoldId != null));
    return formatedLegend;
  }else {
    return []
  }
}

function holdTotal() {
  if (this.items&&this.items.length!=0&&this.vesselHoldId) {
    return this.items.find(item => {
      if (item.PositionJson&&item.PositionJson.some(Hold => Hold.VesselHoldId == this.vesselHoldId)) {
        return true;
      }else{
        return false;
      }
    }).PositionJson[0].AvailableTon;
  }else{
    return 0
  }
}

export default {
  name: 'stowage-plan',
  mixins: [],
  components: {
    TransversalView,
    CImgExtended,
    HoldModal,
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data,
  created,
  directives: UpperCase,
  methods: {
    printPdf,
    getDataFromService,
    validateReport,
    currentHold,
    handleAction,
  },
  computed: {
    placeholder,
    legend,
    formatedLegend,
    holdTotal,
    ...mapState({
      planificacionId: state => state.planificacionestiba.planificacionId,
      TpVesselMasterId: state => state.planificacionestiba.TpVesselMasterId,
      FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
    }),
  },
  watch: {
    active: async function(newVal) {
      if(newVal){
        this.getDataFromService();
      }
    },
    holdModal: function (val) {
      if (!val) {
        this.holdName = '';
        this.vesselHoldId = '';
      }
    },
  }
}
</script>

<style scoped>
.legend-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
}
.legend-color {
  background-color: red;
  width: 25px;
  height: 15px;
  margin-right: 2px;
}

.legend-text {
  font-weight: normal;
}
.wrap-legend {
  flex-wrap: wrap;
}
</style>